<template>
  <div class="main">
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'relayModel-add'">
          <el-button type="primary" size="small" @click="addRelayModel">新增继电器</el-button>
          &nbsp;
        </div>
            <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
                v-loading="spinning"
                :data="data"
                row-key="id"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-form label-position="left" class="demo-table-expand">
                    <el-form-item label="mqtt代理服务器地址">
                      <span>{{ props.row.mqttServiceAddress }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt代理服务器用户名">
                      <span>{{ props.row.mqttServerPassword}}</span>
                    </el-form-item>
                    <el-form-item label="mqtt代理服务器密码">
                      <span>{{ props.row.mqttServerPassword }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt代理服务器端口">
                      <span>{{ props.row.mqttServerPort }}</span>
                    </el-form-item>
                    <el-form-item label="刷新频率">
                      <span>{{ props.row.refreshRate }}</span>
                    </el-form-item>
                    <el-form-item label="版本号">
                      <span>{{ props.row.relayVersion }}</span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column
                  prop="relayDeviceId"
                  label="设备id">
              </el-table-column>
              <el-table-column
                  prop="portNumber"
                  label="继电器规格">
                <template slot-scope="scope">
                  {{scope.row.portNumber+'路'}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="manufacturer"
                  label="生产厂家">
              </el-table-column>
              <el-table-column
                  prop="mqttClientId"
                  label="mqtt客户端id">
              </el-table-column>


              <el-table-column
                  fixed="right"
                  width="200"
                  label="操作"
                  align="center"
                  v-if="authVerify.verify('relayModel-edit,relayModel-delete')"
              >
                <template slot-scope="scope">
                  <el-button icon="el-icon-edit-outline" size="mini" v-auth="'relayModel-edit'"
                             @click="editRelayModel(scope.row)">编辑
                  </el-button>
                  <el-button icon="el-icon-delete" size="mini" v-auth="'relayModel-delete'"
                             @click="deleteRelayModel(scope.row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
      </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
            title="新增继电器"
            width="700"
            placement="right"
            :closable="false"
            :visible="addRelayModelVisible"
            @close="addRelayModelVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="relayModelFormValue" ref="addRefForm"
                          :rules="relayModelRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="设备id" prop="relayDeviceId">
                <a-input
                    v-model="relayModelFormValue.relayDeviceId"
                />
              </a-form-model-item>
              <a-form-model-item label="继电器规格" prop="portNumber">
                <a-select  style="width: 100%" placeholder="请选择" v-model="relayModelFormValue.portNumber">
                  <a-select-option v-for="(i,index) in 64" :key="i">
                    {{ i +'路'}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item label="mqtt客户端id" prop="mqttClientId">
                <a-input
                    v-model="relayModelFormValue.mqttClientId"
                />
              </a-form-model-item>

              <a-form-model-item label="mqtt代理服务器地址" prop="mqttServiceAddress">
                <a-input
                    v-model="relayModelFormValue.mqttServiceAddress"
                />
              </a-form-model-item>
              <a-form-model-item label="生产厂家" prop="manufacturer">
                <a-input
                    v-model="relayModelFormValue.manufacturer"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器用户名" prop="mqttServerUsername">
                <a-input
                    v-model="relayModelFormValue.mqttServerUsername"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器密码" prop="mqttServerPassword">
                <a-input
                    v-model="relayModelFormValue.mqttServerPassword"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器端口" prop="mqttServerPort">
                <a-input
                    v-model="relayModelFormValue.mqttServerPort"
                />
              </a-form-model-item>
              <a-form-model-item label="刷新频率" prop="refreshRate">
                <a-input
                    v-model="relayModelFormValue.refreshRate"
                />
              </a-form-model-item>
              <a-form-model-item label="版本号" prop="relayVersion">
                <a-input
                    v-model="relayModelFormValue.relayVersion"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="addRelayModelVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toAddRelayModel">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
            title="编辑继电器"
            width="700"
            placement="right"
            :closable="false"
            :visible="editRelayModelVisible"
            @close="editRelayModelVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="relayModelFormValue" ref="editRefForm"
                          :rules="relayModelRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="设备id" prop="relayDeviceId">
                <a-input
                    v-model="relayModelFormValue.relayDeviceId"
                />
              </a-form-model-item>
              <a-form-model-item label="继电器规格" prop="portNumber">
                <a-select  style="width: 100%" placeholder="请选择" v-model="relayModelFormValue.portNumber">
                  <a-select-option v-for="(i,index) in 64" :key="i">
                    {{ i +'路'}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item label="mqtt客户端id" prop="mqttClientId">
                <a-input
                    v-model="relayModelFormValue.mqttClientId"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器地址" prop="mqttServiceAddress">
                <a-input
                    v-model="relayModelFormValue.mqttServiceAddress"
                />
              </a-form-model-item>
              <a-form-model-item label="生产厂家" prop="manufacturer">
                <a-input
                    v-model="relayModelFormValue.manufacturer"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器用户名" prop="mqttServerUsername">
                <a-input
                    v-model="relayModelFormValue.mqttServerUsername"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器密码" prop="mqttServerPassword">
                <a-input
                    v-model="relayModelFormValue.mqttServerPassword"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器端口" prop="mqttServerPort">
                <a-input
                    v-model="relayModelFormValue.mqttServerPort"
                />
              </a-form-model-item>
              <a-form-model-item label="刷新频率" prop="refreshRate">
                <a-input
                    v-model="relayModelFormValue.refreshRate"
                />
              </a-form-model-item>
              <a-form-model-item label="版本号" prop="relayVersion">
                <a-input
                    v-model="relayModelFormValue.relayVersion"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="editRelayModelVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toEditRelayModel">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                relayModelFormValue: {
                    id: '',
                    manufacturer: '',
                    mqttClientId: '',
                    relayDeviceId: '',
                    relayVersion: '',
                    mqttServiceAddress: '',
                    mqttServerUsername: '',
                    mqttServerPassword: '',
                    mqttServerPort: '',
                    portNumber: '',
                    refreshRate: '',
                    organizationId: '',
                },
                relayModelRules: {
                    relayDeviceId: [
                        {required: true, message: '请输入继电器id!', trigger: 'blur'},
                    ], portNumber: [
                        {required: true, message: '请输入继电器规格!', trigger: 'blur'},
                    ], mqttClientId: [
                        {required: true, message: '请输入mqtt客户端id!', trigger: 'blur'},
                    ],
                    mqttServiceAddress: [
                        {required: true, message: '请输入mqtt服务器地址!', trigger: 'blur'},
                    ],
                },
                spinning: true,
                screenWidth: document.documentElement.clientWidth,//屏幕宽度
                screenHeight: document.documentElement.clientHeight - 50,//屏幕高度
                addRelayModelVisible: false,
                editRelayModelVisible: false,
                data: [],
            }
        },
        watch: {
            'screenWidth': function (val) { //监听屏幕宽度变化
                console.log(val);
            },
            'screenHeight': function (val) { //监听屏幕高度变化
                console.log(val);
            },
        },
        mounted() {
            const _this = this;
            window.onresize = function () { // 定义窗口大小变更通知事件
                _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
                _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
            };
        },
        created() {
            this.getRelayModelData();
        },
        methods: {
            getRelayModelData() {
                this.http.relay.getRelayModelList()
                    .then(response => {
                        if (response.data.status === 200) {
                            this.spinning = false;
                            this.data = response.data.data;
                        } else {
                            this.$message.warning('数据获取失败');
                        }
                    });
            },
            deleteRelayModel(key) {
                let self = this;
                this.$confirm({
                    title: '确定删除吗?',
                    content: '',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.http.relay.deleteRelayModel({
                            id: key.id
                        })
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    self.getRelayModelData();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            })
                    },
                    onCancel() {
                    },
                });
            },

            addRelayModel() {
                this.cleanRelayModel();
                this.addRelayModelVisible = true;
                  this.$refs.addRefForm.resetFields();
            },
            toAddRelayModel() {
                this.$refs.addRefForm.validate(valid => {
                    if (valid) {
                        console.log(this.relayModelFormValue);
                        this.http.relay.createRelayModel(this.relayModelFormValue).then(response => {
                            if (response.data.status === 200) {
                                this.$message.success(response.data.msg);
                                this.addRelayModelVisible = false;
                            } else {
                                this.$message.warning(response.data.msg);
                            }
                            this.getRelayModelData();
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            editRelayModel(record) {
                console.log(record);
                this.cleanRelayModel();
                this.relayModelFormValue = {
                    id: record.id,
                    manufacturer: record.manufacturer,
                    mqttClientId: record.mqttClientId,
                    relayDeviceId: record.relayDeviceId,
                    relayVersion: record.relayVersion,
                    mqttServiceAddress: record.mqttServiceAddress,
                    mqttServerUsername: record.mqttServerUsername,
                    mqttServerPassword: record.mqttServerPassword,
                    mqttServerPort: record.mqttServerPort,
                    portNumber: record.portNumber,
                    refreshRate: record.refreshRate,
                    organizationId: record.organizationId,
                };
                this.editRelayModelVisible = true;
                  this.$refs.editRefForm.resetFields();
            },
            cleanRelayModel() {
                this.relayModelFormValue = {
                    id: '',
                    manufacturer: '',
                    mqttClientId: '',
                    relayDeviceId: '',
                    relayVersion: '',
                    mqttServiceAddress: '',
                    mqttServerUsername: '',
                    mqttServerPassword: '',
                    mqttServerPort: '',
                    portNumber: '',
                    refreshRate: '',
                    organizationId: '',
                };
            },

            toEditRelayModel() {
                this.$refs.editRefForm.validate(valid => {
                    if (valid) {
                        this.http.relay.updateRelayModel(this.relayModelFormValue)
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    this.editRelayModelVisible = false;
                                } else {
                                    this.$message.warning(response.data.msg);
                                }
                                this.getRelayModelData();
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

        },
    }
</script>

<style scoped>
  /*滚动条整体样式*/
  .scroll_bar::-webkit-scrollbar {
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  }

  .scroll_bar::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #cecece;
  }

  .scroll_bar::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
  }

  .demo-table-expand >>> .el-form-item__label {
    color: #99a9bf;
    font-size: 8px;
  }

  .demo-table-expand >>> .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
